
document.addEventListener("turbolinks:load", function() {

  menu_button = document.getElementById('mobile-toggle-menu-button');

  menu_button.addEventListener('click', (event) =>{

    menu = document.getElementsByClassName('main-navigation')[0];

    menu_button.classList.toggle('open');
    menu.classList.toggle('open');

    event.preventDefault();

  });

});

